import * as _ from 'underscore'
import { Component } from '@angular/core'
import { SessionService } from '@shared/services/session.service'
import { ArraifyPipe } from '@shared/pipes/arraify.pipe'
import { TopMenuService } from './top-menu.service'

@Component({
	selector: 'top-menu',
	templateUrl: './top-menu.component.html',
	styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {

	topMenu : TopMenuService
	session : SessionService
	
	constructor(
		session : SessionService,
		private TopMenuService : TopMenuService
	) {
		this.session = session
		this.topMenu = this.TopMenuService
	}

	get name() : string {
		return this.session.name
	}


	get userType() : string {
		return this.session.isAdmin ? 'admin' : 'user'
	}

	get unreadAlerts() : boolean {
		return false
	}

	logout($event : MouseEvent) : void {
		$event.stopPropagation()
	}
	

}

import { Pipe, PipeTransform } from '@angular/core';

const months = [
	'Enero', 'Febrero', 'Marzo',
	'Abril', 'Mayo', 'Junio',
	'Julio', 'Agosto', 'Septiembre',
	'Octubre', 'Noviembre', 'Diciembre']

@Pipe({ name: 'datify' })
export class DatifyPipe implements PipeTransform {

	transform(UNIX_date : number): any {
		let date = new Date(UNIX_date)
		let hour = date.getHours()
		let m = 'am'
		if(hour > 12) {
			hour -= 12
			m = 'pm'
		}
		return `${date.getDay()} de ${months[date.getMonth()]} del ${date.getFullYear()}, ${hour}:${date.getMinutes()} ${m}`
	}

}

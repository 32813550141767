import { Component, OnInit } from '@angular/core';
import { SessionService } from '@shared/services/session.service';

@Component({
	selector: 'admin-menu',
	templateUrl: './admin-menu.component.html',
	styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {

	constructor(
		private session : SessionService
	) {
		// TODO: DELETE
		this.session.isLoggedIn()
	}

	ngOnInit() {
		
	}

	get name() : string {
		return this.session.name
	}

	get uid() : string {
		return this.session.uid
	}

}

import { Injectable } from '@angular/core'

@Injectable()
export class StringsService {

	constructor() { }

	toKey(string : string) : string {
		return string
		// Accents
		.toLowerCase()
		.replace(/á/g, 'a')
		.replace(/é/g, 'e')
		.replace(/í/g, 'i')
		.replace(/ó/g, 'o')
		.replace(/ú/g, 'u')
		.replace(/ñ/g, 'n')
		.replace(/\s/g, '-')
		.replace(/\./g, '')
		.replace(/\#/g, '')
		.replace(/\$/g, '')
		.replace(/\[/g, '')
		.replace(/\]/g, '')
	}

	randomString() : string {
		return Math.random().toString(36).substring(7);
	}

}
import { Injectable } from '@angular/core'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import { AngularFireDatabase } from '@angular/fire/database'
import { IFileUpload } from 'src/app/types'
import { FirebaseUploadModel } from '@shared/model/firebase-upload.service'

@Injectable()
export class UploadService {

	// The base dir in which the files will be uploaded
	private basePath : string
	// Action to execute after uploading a file. Returns an IFileUpload object
	private onFileUpload : Function 
	// Executes after ALL the files in the queue are uploaded
	private onQueueDone : Function
	// Firebase Storage Reference
	private Storage : firebase.storage.Reference
	// Upload queue
	queue : Array<Promise<IFileUpload>>

	constructor(private db : AngularFireDatabase) {
		this.Storage = firebase.storage().ref()
		this.queue = []
	}


	// Takes a FirebaseUpload object and uploads it to Firebase
	// Pushes the upload promise into queue
	push(upload : FirebaseUploadModel) : void {
		let uploadUrl = `${this.basePath}/${upload.file.name}`
		let task = this.Storage.child(uploadUrl).put(upload.file)
		let promise = new Promise<IFileUpload>((resolve, reject) => {
			// Update upload progress
			task.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
				let total = task.snapshot.bytesTransferred
				let current = task.snapshot.totalBytes
				upload.progress = (total / current) * 100
			}, err => reject(), () => {
				resolve()
				// Get download URL and create IFileUploadData
				task.snapshot.ref.getDownloadURL().then(url => {
					let data = this.makeFileUpload(upload, task, url)
					this.onFileUpload(data)
				})
			})
		})
		this.queue.push(promise)
	}


	// Listens to queue and executes onQueueDone when
	// all uploads are finished
	listenToQueue() : void {
		Promise.all(this.queue).then(() => this.onQueueDone())
	}


	// Removes file from firebase given a path
	deleteFile(path : string) : Promise<any> {
		return this.Storage.child(path).delete()
	}


	// Creates an IFileUpload data object from
	// a FirebaseUpload and an UploadTask
	private makeFileUpload(
		upload : FirebaseUploadModel,
		task : firebase.storage.UploadTask,
		url : string) : IFileUpload {
			return {
				name: upload.file.name,
				path: task.snapshot.metadata.fullPath,
				url: url,
				created : task.snapshot.metadata.timeCreated
			}
	}
	

	// Setters
	
	setBasePath(basePath : string) { this.basePath = basePath }

	setOnQueueDone(fn : Function) { this.onQueueDone = fn }

	setOnFileUpload(fn : Function)  { this.onFileUpload = fn }
	
}

import { Pipe, PipeTransform } from '@angular/core'

// Turns an object into an array
@Pipe({ name: 'arraify' })
export class ArraifyPipe implements PipeTransform {
	transform(object : any) : Array<any> {
		let arr = []
		for(let id in object) arr.push(object[id])
		return arr
	}
}

import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { SessionService } from '@shared/services/session.service'
import { reject } from 'q'

@Injectable()
export class RedirectorGuard implements CanActivate {
	
	constructor(
		private router : Router,
		private session : SessionService
	) { }

	canActivate() : Promise<boolean> {
		return new Promise(() => {
			this.session.isLoggedIn()
			.then(loggedIn => {
				if(loggedIn) {
					// Check if admin
					if(this.session.isAdmin) {
						this.router.navigate(['admin'])
					// Check if hub is set
					} else {
						if(this.session.hub) {
							this.router.navigate(['classroom', this.session.hub])
						} else {
							this.router.navigate(['register'])
						}
					}
					reject(true)
				} else {
					if(this.session.uid && !this.session.hub) {
						this.router.navigate(['register'])
					} else {
						this.router.navigate(['sign-in'])
					}
					reject(false)
				}
			})
		})
	}

}

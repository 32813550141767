import * as _ from 'underscore'
import * as firebase from 'firebase/app'
import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth'
import { IAlert } from 'src/app/types'
import { LoginErrors } from './session.service.strings'
import { Observable } from 'rxjs'
import { Dictionary } from 'underscore'


@Injectable({
	providedIn: 'root'
})
export class SessionService {

	uid : string
	code : string
	name : string
	alerts : Dictionary<IAlert>
	lastName : string
	hub : string
	errors = LoginErrors
	user : Observable<firebase.User>
	isAdmin : boolean = false

	constructor(
		private firebaseAuth : AngularFireAuth,
		private db : AngularFireDatabase
	) { }
	

	isLoggedIn() : Promise<boolean> {
		return new Promise((resolve, reject) => {
			let obs = this.firebaseAuth.authState
			.subscribe(user => {
				if(!user) {
					resolve(false)
				} else {
					this.uid = user.uid
					this.updateFromDatabase(resolve)
				}
			})
		})
	}

	signOut() : Promise<any> {
		this.hub = null
		this.name = null
		this.lastName = null
		this.uid = null
		this.alerts = null
		return this.firebaseAuth.auth.signOut()
	}

	getUnreadAlerts() : Dictionary<IAlert> {
		let unread = {}
		for(let id in this.alerts) {
			let alert = this.alerts[id]
			if(!alert.read) unread[id] = alert
		}
		return unread
	}

	readAlert(alertId : string) : void {
		if(!this.alerts) return
		this.alerts[alertId].read = true
		this.db.object(`users/${this.uid}/alerts/${alertId}/isRead`).set(true)
	}

	private sessionIsStarted() : boolean {
		return this.uid !== undefined && this.uid !== null &&
		this.hub !== undefined && this.hub !== null
	}

	private updateFromDatabase(resolve : Function) : void {
		let obs = this.db.object(`users/${this.uid}`)
		.valueChanges().subscribe(user => {
			// If rt database does not contain user, ignore
			if(user !== null) {
				this.name 		= user['name']
				this.code 		= user['code']
				this.lastName 	= user['lastName']
				this.hub 		= user['hub']
				this.alerts		= user['alerts']
				this.updateLastLogin()
				resolve(true)
			} else {
				resolve(false)
			}
			obs.unsubscribe()
		})
	}

	updateLastLogin() : void {
		if(this.uid)
			this.db.object(`users/${this.uid}/last_login`)
			.set(firebase.database.ServerValue.TIMESTAMP)
	}

}
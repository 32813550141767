import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppContainerComponent } from '@public/app-container/app-container.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// Firebase
import { AngularFireModule } from '@angular/fire'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { SharedModule } from '@shared/shared.module'
import { environment } from 'src/environments/environment'
import { TopMenuComponent } from '@public/top-menu/top-menu.component'
import { TopMenuService } from '@public/top-menu/top-menu.service'
import { 
	MatDialogModule, 
	MatIconModule, 
	MatMenuModule, 
	MatTabsModule, 
	MatProgressSpinnerModule 
} from '@angular/material';
import { AdminMenuComponent } from '@public/admin-menu/admin-menu.component'
import { UploadsWizardComponent } from '@public/uploads-wizard/uploads-wizard.component'


@NgModule({
	declarations: [
		AppContainerComponent,
		AdminMenuComponent,
		TopMenuComponent,
		UploadsWizardComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		// Shared Module
		SharedModule.forRoot(),
		// Material
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatTabsModule,
		MatProgressSpinnerModule,
		//Firebase
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
		AngularFireAuthModule
	],
	providers: [TopMenuService],
	bootstrap: [AppContainerComponent]
})
export class AppModule { }

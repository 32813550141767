import * as _ from 'underscore'
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core'
import { MatSnackBar } from '@angular/material'
import { FirebaseUploadModel } from '@shared/model/firebase-upload.service'
import { UploadService } from '@shared/services/upload.service'
import { HTMLInputEvent } from 'src/app/types'

@Component({
	selector: 'file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

	@ViewChild('filesInput', { static: false }) filesInput
	@Input() dir : string
	@Output('on-all-done') onAllDone ? = new EventEmitter()
	@Output() onFileUpload = new EventEmitter

	selectedFiles : FileList
	currentUpload : FirebaseUploadModel

	uploads : Array<FirebaseUploadModel> = []

	

	constructor(
		private snackBar : MatSnackBar,
		private Upload : UploadService
	) { }
	
	ngOnInit() {
		this.Upload.setBasePath(this.dir)

		this.Upload.setOnFileUpload(file => {
			this.onFileUpload.emit(file)
		})

		this.Upload.setOnQueueDone(() => {
			if(this.onAllDone) this.onAllDone.emit()

			// Snackbar
			let ref = this.snackBar.open('Tus archivos se han guardado', 'Ok')
			ref.onAction().subscribe(() => this.clearUploads())
		})
	}

	private clearUploads() {
		this.uploads = []
		this.Upload.queue = []
	}

	uploadFiles(event : HTMLInputEvent) : void {
		let files = event.target.files
		let filesIndex = _.range(files.length)
		_.each(filesIndex, (idx) => {
			let upload = new FirebaseUploadModel(files[idx])
			this.uploads.push(upload)
			this.Upload.push(upload)
		})
		this.Upload.listenToQueue()
	}

	get doneWithUploads() : boolean {
		if(this.uploads.length == 0) {
			return true
		} else {
			let upload_progress = _.pluck(this.uploads, 'progress')
			return _.all(upload_progress, u => u == 100)
		}
	}

	getUploadName(upload : FirebaseUploadModel) {
		return upload.file.name
	}

	startUpload() : void {
		this.filesInput.nativeElement.click()
	}

}

import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'

@Injectable()
export class TopMenuService {

	display : boolean = false
	
	constructor(
		private router : Router,
		private location : Location
	) {
		this.router.events.subscribe(() => {
			let chunks = this.location.normalize(this.location.path()).split('/')
			this.display = (chunks[1] !== 'auth' && chunks[1] !== undefined)
		})
	}

}

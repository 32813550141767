import { Injectable, ElementRef } from '@angular/core'
import { ChartOptions, ChartDataSets } from 'chart.js'
import * as Chart from 'chart.js'
import { ColorsService } from './colors.service'

const DEFAULT_OPTIONS : ChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	legend: { display : false },
}

@Injectable()
export class ChartService {

	type : string
	context : ElementRef
	labels : Array<string>
	datasets : Array<ChartDataSets>
	options : ChartOptions

	_chart : Chart

	constructor(
		private Color : ColorsService
	) { }

	init() : ChartService {
		this.datasets = []
		this.options = DEFAULT_OPTIONS
		return this
	}
	

	setType(type : string) : ChartService {
		this.type = type
		return this
	}

	setContext(context : ElementRef) : ChartService {
		this.context = context
		return this
	}

	setLabels(labels : Array<string>) : ChartService {
		this.labels = labels
		return this
	}

	setTwoLabelsOnly() : ChartService {
		this.options.scales = {
			xAxes: [{
				ticks: { autoSkip: true, maxTicksLimit: 2, maxRotation: 0}
			}]
		}
		return this
	}

	setYAxisValues(min : number, max: number) : ChartService {
		this.options.scales.yAxes = [
			{
				ticks: {
					min: min,
					max: max
				}
			}
		]
		return this
	}

	setXAxisValues(min : number, max: number) : ChartService {
		this.options.scales.xAxes = [
			{
				ticks: {
					min: min,
					max: max
				}
			}
		]
		return this
	}

	setOnClick(fn : Function) : ChartService {
		this.options.onClick = fn as any
		return this
	}

	setMax(max : number) : ChartService {
		this.options.scales = {
			ticks: {
				beginAtZero: true,
				max: max,
				maxTicksLimit: 5,
			}
		}
		return this
	}

	setLineTension(n : number) : ChartService {
		this.options.elements = { line: { tension: n} }
		return this
	}

	showLegend() : ChartService {
		this.options.legend.display = true
		return this
	}

	hideScales() : ChartService {
		delete this.options.scales
		return this
	}

	addDataSet(ds : ChartDataSets) : ChartService {
		this.datasets.push(ds)
		return this
	}

	draw() : Chart {
		return new Chart(this.context.nativeElement, {
			type: this.type,
			options: this.options,
			data: {
				labels: this.labels,
				datasets: this.datasets
			}
		})
	}

	makeDataset(label : string, data : Array<number>, color : string) : any {
		return {
			label: label,
			pointRadius: 3,
			pointBorderWidth: 2,
			pointBorderColor: color,
			pointHoverBackgroundColor: color,
			pointBackgroundColor: '#fff',
			borderColor: color,
			backgroundColor: this.Color.opacity(color, 0.4),
			data: data
		}
	}

}

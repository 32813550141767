import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { StudentGuard } from '@shared/guards/student-guard.service'

const routes: Routes = [
	{
		path: '',
		loadChildren: './public/public.module#PublicModule'
	},
	{
		path: 'admin',
		loadChildren: './admin/admin.module#AdminModule'
	},
	{
		path: 'classroom',
		loadChildren: './classroom/classroom.module#ClassroomModule',
		canActivate: [StudentGuard]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core'

@Injectable()
export class ObjectsService {

	constructor() { }
	
	countProperties(object : any) : number {
		let n = 0
		for(let _ in object) n++
		return n
	}

}
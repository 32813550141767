import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router'
import { RouterStateSnapshot } from '@angular/router'
import { SessionService } from '@shared/services/session.service'

@Injectable()
export class StudentGuard implements CanActivate {
	
	constructor(
		private router : Router,
		private session : SessionService
	) { }

	canActivate(route : ActivatedRouteSnapshot, state ? : RouterStateSnapshot) : Promise<boolean> {
		let hub = route.children[0].params.hub
		return new Promise((resolve, reject) => {
			this.session.isLoggedIn().then(res => {
				if(this.session.isAdmin) {
					resolve(false)
					this.router.navigate(['/', 'admin'])
				} else if(this.session.hub) {
					resolve(true)
				} else {
					resolve(false)
					this.router.navigate(['/forbidden'], { queryParams: { t : 'not-student', hub : hub } })
				}
			})
		})
	}

}

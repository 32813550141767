import { Injectable } from '@angular/core'
import { DEFAULT_PALETTE } from './../constants/colors'

@Injectable()
export class ColorsService {

	public DEFAULT_PALETTE = DEFAULT_PALETTE

	constructor() {}

	
	// Takes #hex string @hex and turns into an rgba color with
	// opacity of @opacity
	opacity(color : string, opacity: number) : string {
		return this.hexToRGBA(color, opacity)
	}


	// Converts a hexadecimal color into an rgba value
	private hexToRGBA(hex : string, opacity : number) : string {
		let c : Array<string>
		let rgba : any
		if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
			c = hex.substring(1).split('')
			if(c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			rgba = '0x' + c.join('');
			return `rgba(
				${(rgba>>16) & 255},
				${(rgba>>8) & 255},
				${rgba & 255},
				${opacity}
			)`
		}
		throw new Error('Bad Hex')
	}
}

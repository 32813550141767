export const LoginErrors = {
	'auth/not-registered': '¡La cuenta que ingresaste no ha sido registrada en atticus.cc!',
	'auth/email-already-in-use': 'El email que ingresaste ya está en uso',
	'auth/password-too-short': 'El password tiene que ser de al menos 6 caracteres',
	'auth/insufficient-data': '¡El nombre y los apellidos no pueden quedar en blanco!',
	'auth/bad-passwords': '¡Los passwords no concuerdan!',
	'auth/argument-error': '¡Por favor ingresa credenciales válidas!',
	'auth/invalid-email': 'El email que ingresaste no es una dirección válida',
	'auth/user-not-found': 'El usuario que ingresaste no existe',
	'auth/wrong-password': 'El password que ingresaste es incorrecto',
	'auth/too-many-requests': '¡Demasiados intentos incorrectos! Por favor espera unos momentos.'
}
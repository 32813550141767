import {
	Component,
	OnInit,
	ViewChild,
	Inject,
	Input,
	Output,
	EventEmitter
} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { DevicesService } from '@shared/services/devices.service';

@Component({
	selector: 'video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

	@Input() header : string
	@Input() icon : string
	@Output() onBack = new EventEmitter<string>()
	@Output() done = new EventEmitter<void>()
	@Input() videoURL : string

	// Video player html div reference
	@ViewChild('container', {static : true}) containerElement : any
	// Video player html div reference
	@ViewChild('videoPlayer', {static : true}) videoPlayerElement : any
	// Video progress div reference
	@ViewChild('progressElement', {static : true}) progressElement : any
	videoPlayer : HTMLVideoElement // Video player reference
	progressEl : HTMLElement // Progres element reference
	containerEl : HTMLElement // Container reference (For fullscreen)
	loading : boolean = true // Whether the video is loading
	progress : number // Percentage progress of video
	visibleControls : boolean = false // Determines if progress controls are visible
	isMobile : boolean
	timeoutId : number // setTimeout control's reference
	isFullScreen : boolean = false

	sent_done_msg : boolean = false

	REFRESH_RATE = 500
	CONTROLS_TIMER = 2500

	// Inject document reference for fullscreen exits
	constructor(
		private Devices : DevicesService,
		@Inject(DOCUMENT) private document : any
	) { }

	ngOnInit() {
		this.isMobile = this.Devices.isMobile()
		this.videoPlayer = this.videoPlayerElement.nativeElement
		this.progressEl = this.progressElement.nativeElement
		this.videoPlayer.oncanplay = () => this.initVideo()
		this.containerEl = this.containerElement.nativeElement
	}

	ngOnDestroy() {
		console.log('destroyed element', this.videoPlayer)
		console.log(this.videoPlayer.currentTime)
	}


	initVideo() {
		this.loading = false
		this.updateProgress()
	}


	updateProgress() {
		setTimeout(() => {
			this.progress = (this.videoPlayer.currentTime / this.videoPlayer.duration) * 100
			this.updateProgress()
			if(this.progress > 99.9 && ! this.sent_done_msg) this.finishVideo()
		}, this.REFRESH_RATE)
	}

	private finishVideo() : void {
		this.sent_done_msg = true
		if(this.done) this.done.emit()
	}


	showControls() : void {
		this.visibleControls = true
		this.clearControlTimeout()
		this.timeoutId = window.setTimeout(() => {
			this.visibleControls = false
		}, this.CONTROLS_TIMER)
	}


	clearControlTimeout() : void {
		clearTimeout(this.timeoutId)
	}


	togglePlay() : void {
		if(this.videoPlayer.paused) {
			this.visibleControls = false
			this.videoPlayer.play()
		} else {
			this.videoPlayer.pause()
		}
	}


	overscreenMove() : void {
		if(!this.isMobile) this.showControls()
	}


	overscreenClick() : void {
		if(!this.isMobile || (this.isMobile && this.visibleControls)) {
			this.togglePlay()
		} else {
			this.showControls()
		}
	}


	toggleFullscreen() : void {
		if(this.isFullScreen) {
			this.Devices.closeFullscreen(this.document)
			this.isFullScreen = false
		} else {
			this.Devices.openFullscreen(this.containerEl)
			this.isFullScreen = true
		}
	}


	seek(event : MouseEvent) : void {
		let w = this.progressEl.clientWidth
		let x = event.offsetX
		let p = x / w
		this.progress = p * 100
		this.videoPlayer.currentTime = this.videoPlayer.duration * p
	}

	back() : void {
		this.onBack.emit('back')
	}

}

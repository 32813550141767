import { Injectable } from '@angular/core'
import { ICourse, ILecture, IUserProgress, IPendingActivity } from 'src/app/types'
import * as _ from 'underscore'
import { ArraifyPipe } from '@shared/pipes/arraify.pipe'
import { Dictionary } from 'underscore'

@Injectable()
export class CoursesService {

	constructor(
		private Arraify : ArraifyPipe
	) { }

	// Returns an array of all of the lectures that
	// are yet to be scored inside of a course
	getUnscoredActivities(
		course_id : string,
		users : Dictionary<IUserProgress>
	) : Array<IPendingActivity> {
		let unscored = []
		// Loop through every user
		for(let user_id in users) {
			// Loop through its course progress
			let user = users[user_id]
			let user_pending = this.getUserActivities(course_id, user_id, user)
			unscored = unscored.concat(user_pending)
		}
		return unscored
	}


	// Returns all activity lectures in a course
	private getActivityLectures(course : ICourse) : Array<ILecture> {
		let lectures = this.Arraify.transform(course.lectures)
		let activity_lectures = _.findWhere(lectures, { type : 'activity' })
		return activity_lectures
	}
	
	// Returns all the activities finished by a specific user
	private getUserActivities(
		course_id : string,
		user_id : string,
		user : IUserProgress
	) : Array<IPendingActivity> {
		let activities = []
		
		// Get the progress in specified course
		if(!user.courseProgress) return []
		let progress = user.courseProgress[course_id]
		if(!progress) return []

		// Loop through every finished lecture
		for(let lecture_id in progress) {
			let data = progress[lecture_id]
			if(data.updated) activities.push({
				user_id : user_id,
				lecture_id : lecture_id,
				data : data
			})
		}

		return activities
	}


}

import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material'
import { ConfirmationDialogComponent } from './confirmation-dialog.component'

@Directive({
	selector: '[confirm-dialog]'
})
export class ConfirmationDialogDirective {

	@Output() confirm = new EventEmitter<any>()
	@Input() message : string

	ref : MatDialogRef<any> // For testing!cibn
	
	constructor(private dialog : MatDialog) { }

	@HostListener('click') click() {
		this.ref = this.dialog.open(ConfirmationDialogComponent)
		this.ref.componentInstance.message = this.message
		this.ref.componentInstance.accept = () => {
			this.confirm.emit()
		}
	}

}

import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
	Router,
	RouteConfigLoadStart,
	RouteConfigLoadEnd
} from '@angular/router'
import { TopMenuService } from '@public/top-menu/top-menu.service'

@Component({
	selector: 'app-root',
	templateUrl: './app-container.component.html',
	styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit {
	
	show_uploads : boolean = false

	topMenu : TopMenuService
	loadingModule : boolean = false

	constructor(
		private TopMenu : TopMenuService,
		private router : Router,
		private location : Location
	) { }
	
	ngOnInit() {
		this.is_admin
		this.topMenu = this.TopMenu
		// Listen to module loads
		this.router.events.subscribe(event => {
			if(event instanceof RouteConfigLoadStart) {
				this.loadingModule = true
			} else if (event instanceof RouteConfigLoadEnd) {
				this.loadingModule = false
			}
		})
	}

	fuck() : void {
		this.show_uploads = !this.show_uploads
	}

	get is_admin() : boolean {
		// return false
		let chunks = this.location.path().split('/')
		return chunks[1] == 'admin' ? true : false
	}

}
import * as _ from 'underscore'
import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { ICourse, ILectureProgress } from 'src/app/types'
import { ArraifyPipe } from '@shared/pipes/arraify.pipe'
import { ObjectsService } from '@shared/services/objects.service'
import { Dictionary } from 'underscore'

@Injectable()
export class CourseModel {
	
	constructor(
		private Object : ObjectsService,
		private Arraify : ArraifyPipe,
		private db : AngularFireDatabase
	) { }


	// Loads a course formatted as a nested array to
	// display in the client
	loadCourseDisplay(courseId : string) : Promise<any> {
		return new Promise((resolve, reject) => {
			let url = `courses/${courseId}`
			this.db.object(url).valueChanges().subscribe(res => {
				let course = this.toDisplay(res as ICourse)
				resolve(course)
			}, () => reject())
		})
	}

	
	setProgress(course : ICourse, progress : Dictionary<ILectureProgress>) : void {
		if(!progress) {
			course.$progress = 0
			return
		}
		let finished = 0
		for(let lectureId in course.lectures) {
			if(progress[lectureId]) finished++
		}
		let total = this.Object.countProperties(course.lectures)
		course.$progress = Math.floor((finished / total) * 100)
	}


	// Turns a simple course object into a nested object to be displayed
	// in the client
	toDisplay(course : ICourse) : any {
		// Apend the firebase key to each lecture as its id
		for(let lectureId in course.lectures) course.lectures[lectureId].id = lectureId
		// Turn all lectures into an array
		let lectures = this.Arraify.transform(course.lectures)
		// Group by module
		let arrays = []
		let modules = _.groupBy(lectures, 'module')
		// Group by level
		let i = 0
		for(let moduleId in modules) {
			let levels = _.groupBy(modules[moduleId], 'level')
			// In older versions of rixsi, course.modules were a simple
			// string array. In newer versions, they are objects containing
			// { name : string, text : string }. This bit is necessary for
			// backwards compatibility!.
			let mod_data = course.modules[i] as any
			let module_name = mod_data
			let module_text = ''
			if (mod_data.name) {
				module_name = mod_data.name
				module_text = mod_data.text
			}

			let module = { name: module_name, text: module_text, levels: levels }
			arrays.push(module)
			i++
		}
		course['$modules'] = arrays
		return course
	}

	
	// Helps looping through ordered lectures after
	// a course has been run through @toDisplay
	loopLectures(course : ICourse, fn : Function) : void {
		if(course.$modules === undefined) this.toDisplay(course)
		course.$modules.forEach(module => {
			for(let n in module.levels) {
				module.levels[n].forEach(lecture => {
					fn(lecture)
				})
			}
		})
	}

}

import { Injectable } from '@angular/core';

@Injectable()
export class InputsService {
	
	constructor() { }

	isEnterKey(event : KeyboardEvent) : boolean {
		return (
			event.code === 'Enter' ||
			event.key === 'Enter' ||
			event.keyCode === 13 ||
			event.which === 13
		)
	}

}

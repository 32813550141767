import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputsService } from './services/inputs.service'
import { SessionService } from './services/session.service'
import { LoggedInGuard } from './guards/logged-in.service'
import { StudentGuard } from './guards/student-guard.service'
import { RedirectorGuard } from './guards/redirector.service'
import { ObjectsService } from './services/objects.service'
import { ArraifyPipe } from './pipes/arraify.pipe'
import { CourseModel } from './model/course.service'
import { LoaderComponent } from './components/loader/loader.component'
import { 
	MatButtonModule, 
	MatDialogModule, 
	MatIconModule, 
	MatInputModule, 
	MatFormFieldModule, 
	MatMenuModule, 
	MatProgressSpinnerModule, 
	MatSnackBarModule, 
	MatTooltipModule 
} from '@angular/material';
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { DevicesService } from './services/devices.service';
import { SecondsToRuntimePipe } from './pipes/seconds-to-runtime.pipe'
import { StringsService } from './services/strings.service'
import { UploadService } from './services/upload.service';
import { ConfirmationDialogComponent } from './directives/confirmation-dialog/confirmation-dialog.component'
import { ConfirmationDialogDirective } from './directives/confirmation-dialog/confirmation-dialog.directive';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component'
import { ChartService } from './services/chart.service'
import { ColorsService } from './services/colors.service'
import { CoursesService } from './services/courses.service';
import { DatifyPipe } from './pipes/datify.pipe'



@NgModule({
	declarations: [
		ArraifyPipe,
		LoaderComponent,
		VideoPlayerComponent,
		SecondsToRuntimePipe,
		ConfirmationDialogComponent,
		ConfirmationDialogDirective,
		FileUploaderComponent,
		DatifyPipe
	],
	providers: [
		ArraifyPipe,
		SecondsToRuntimePipe,
		DatifyPipe
	],
	entryComponents: [
		ConfirmationDialogComponent
	],
	imports: [
		CommonModule,
		// Material
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatFormFieldModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTooltipModule
	],
	exports: [
		// Pipes
		ArraifyPipe,
		SecondsToRuntimePipe,
		DatifyPipe,
		// Components
		LoaderComponent,
		VideoPlayerComponent,
		FileUploaderComponent,
		// Directives
		ConfirmationDialogDirective
	]
})
export class SharedModule {
	
	static forRoot() : ModuleWithProviders {
		return {
			ngModule : SharedModule,
			providers: [
				// Models
				CourseModel,
				// Services
				DevicesService,
				InputsService,
				ObjectsService,
				SessionService,
				StringsService,
				UploadService,
				ChartService,
				ColorsService,
				CoursesService,
				//Guards
				LoggedInGuard,
				StudentGuard,
				RedirectorGuard
			]
		}
	}

}

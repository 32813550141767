import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'secondsToRuntime' })
export class SecondsToRuntimePipe implements PipeTransform {
	transform(time : number) : string {
		if(!time) return '0:00'
		let seconds = Math.round(time % 60)
		let minutes = Math.round(time / 60)
		return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`
	}
}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'uploads-wizard',
	templateUrl: './uploads-wizard.component.html',
	styleUrls: ['./uploads-wizard.component.scss']
})
export class UploadsWizardComponent implements OnInit {

	constructor() { }

	MENU_HEIGHT : number = 50;
	ROW_HEIGHT : number = 50;

	current_uploads = [
		{ name: 'tb.mp4', type: 'video', progress: 65 },
		{ name: 'Analisis de sistemas.mp4', type: 'video', progress: 23 },
		{ name: 'Manual.pdf', type: 'pdf', progress: 84 }
	]

	ngOnInit() {
	}

}

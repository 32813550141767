import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router'
import { RouterStateSnapshot } from '@angular/router'
import { SessionService } from '@shared/services/session.service'

@Injectable()
export class LoggedInGuard implements CanActivate {
	
	constructor(
		private session : SessionService
	) { }

	canActivate(route ? : ActivatedRouteSnapshot, state ? : RouterStateSnapshot) {
		return this.session.isLoggedIn()
	}

}

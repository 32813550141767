export const DEFAULT_PALETTE = 
{
  "$primary": "#52aff9",
  "$warn": "#ff5964",
  "$success": "#4ecdc4",
  "$light": "#e3e1de",
  "$medium": "#646464",
  "$gold": "#f4b740",
  "$dark": "#252525",
  "$purple": "#6457a6"
}

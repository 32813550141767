import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	
	accept : Function
	message : string

	constructor() { }
	
	ngOnInit() { }

}
